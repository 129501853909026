/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { PageHeader, Button, Row, Col, Divider, Layout, Input, Breadcrumb } from 'antd'
import Axios from 'axios'
import { ContentItem } from '@antegma/accallio-cloud-hootsuite-application-api-javacript-sdk'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/hootsuite/common/redux/interface'
import contentSourceApi from '~/services/ContentSource'
import CantoBreadCrumb from '~/components/canto-breadcrumb'
import MediaMasonry from '~/components/media-masonry'

const { Footer } = Layout
const { Search } = Input

const ContentItems: React.FunctionComponent = () => {
  const route = useRouteMatch<{ contentId: string }>()
  const dispatch = useDispatch()
  const [query, setQuery] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [total, setTotal] = useState<number>(0)
  const [items, setItems] = useState<ContentItem[]>([])
  const [currentContentItem, setCurrentContentItem] = useState<ContentItem>({})
  const oidc = useSelector((state: TDefaultSagas) => state.oidc)

  const options = { headers: { Authorization: `Bearer ${oidc.user?.access_token}` } }

  const {
    params: { contentId },
  } = route

  const handleLoadCollection = (newSearch: boolean, currentPage: number): void => {
    setIsLoading(true)
    let parentContentItemId: string | undefined
    if (contentId !== 'home') {
      parentContentItemId = contentId
    }
    contentSourceApi.getContentItems(currentPage, parentContentItemId, query, options).then((response) => {
      const contentCollection = response.data
      if (contentCollection.items !== undefined) {
        if (newSearch) {
          setItems(contentCollection.items)
        } else {
          setItems(items.concat(contentCollection.items))
        }
      }
      if (contentCollection.page !== undefined) {
        setPage(contentCollection.page)
      }
      if (contentCollection.total !== undefined) {
        setTotal(contentCollection.total)
      }
      if (contentCollection.pageSize !== undefined) {
        setPageSize(contentCollection.pageSize)
      }
      setIsLoading(false)
    })
  }

  const handleLoadContentItem = (): void => {
    setIsLoading(true)
    if (contentId === 'home') {
      setCurrentContentItem({})
      handleLoadCollection(true, page)
    } else {
      contentSourceApi.getContentItem(contentId, options).then((response) => {
        setCurrentContentItem(response.data)
        handleLoadCollection(true, page)
      })
    }
  }

  useEffect(() => {
    handleLoadContentItem()
  }, [contentId])

  const onLoadNextPage = (): void => {
    handleLoadCollection(false, page + 1)
  }

  const onSearch = (value): void => {
    console.log(value)
    setQuery(value)
    handleLoadCollection(true, 1)
  }

  return (
    <>
      <PageHeader title="Browse Content">
        <CantoBreadCrumb contentPathEntries={currentContentItem.path} />
        <Search placeholder="Search" onSearch={onSearch} enterButton />
        <Divider />
        <MediaMasonry items={items} />
        {total > page * pageSize ? (
          <Footer>
            <Divider />
            <Row>
              <Col offset={8} span={8} style={{ textAlign: 'center' }}>
                <Button disabled={isLoading} onClick={onLoadNextPage} type="dashed">
                  Load more
                </Button>
              </Col>
            </Row>
          </Footer>
        ) : null}
      </PageHeader>
    </>
  )
}

export default {
  name: 'ContentItems',
  routeProps: {
    path: '/contents/:contentId',
    component: ContentItems,
  },
  navProps: {
    title: 'Content',
  },
}
