/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { FolderOpenTwoTone } from '@ant-design/icons'
import { Card, notification, Descriptions } from 'antd'
import Meta from 'antd/lib/card/Meta'
import TimeAgo from 'timeago-react'
import { ContentItem } from '@antegma/accallio-cloud-hootsuite-application-api-javacript-sdk'
import styles from './styles.module.scss'

interface IProps {
  actions: ReactElement[]
  contentItem: ContentItem
  onLoad?: () => void
  showHover?: boolean
}
const SocialCard: React.FunctionComponent<IProps> = ({ actions, onLoad, contentItem, showHover }: IProps): JSX.Element => {
  const { urls: { thumbnail } = {}, name, created, width, height } = contentItem
  const [createdDate, setCreateDate] = useState<Date>()
  const [isLoading, setLoading] = useState<boolean>(!!thumbnail)
  const [isHidden, setHideCard] = useState<boolean>(false)
  const [showImage, setShowImage] = useState<boolean>(!!thumbnail)

  const onImageLoad = (): void => {
    setLoading(false)
    if (created !== undefined) {
      setCreateDate(new Date(created))
    }
    if (onLoad) {
      onLoad()
    }
  }

  const onImageError = (e): void => {
    console.error(e)
    notification.error({
      message: 'Image Load Error',
      description: `Image of Post ${contentItem.id} failed`,
    })
    /* setHideCard(true) */
    setShowImage(false)
    onImageLoad()
  }

  return (
    <>
      {contentItem.id?.includes('folder-') || contentItem.id?.includes('album-') ? (
        <Link to={`/contents/${contentItem.id}`}>
          <Card
            actions={actions}
            className={styles.status}
            cover={<FolderOpenTwoTone style={{ fontSize: '5em', marginTop: '0.5em' }} />}
            hidden={isHidden}
            loading={isLoading}
          >
            <Meta title={name} />
          </Card>
        </Link>
      ) : (
        <Link to={`/contentdetails/${contentItem.id}`}>
          <Card
            actions={actions}
            className={styles.status}
            cover={showImage ? <img alt={name} onError={onImageError} onLoad={onImageLoad} src={thumbnail} width={100} /> : null}
            hidden={isHidden}
            loading={isLoading}
          >
            <Meta
              title={name}
              description={
                <Descriptions size="small" column={1}>
                  <Descriptions.Item label="Width">{width}</Descriptions.Item>
                  <Descriptions.Item label="Height">{height}</Descriptions.Item>
                </Descriptions>
              }
            />
          </Card>
        </Link>
      )}
    </>
  )
}

export default SocialCard
