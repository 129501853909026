import { ContentItem } from '@antegma/accallio-cloud-hootsuite-application-api-javacript-sdk'
import { AnyAction } from 'redux'
import actions from './actions'

export type TContentCollectionWithItemState = {
  items: ContentItem[]
  page: number
  pageSize: number
  total: number
  loading: boolean
}
const initialState = {
  items: [],
  loading: false,
  page: 1,
  pageSize: 10,
  total: 0,
}
export default function collectionReducer(state: TContentCollectionWithItemState = initialState, action: AnyAction): TContentCollectionWithItemState {
  switch (action.type) {
    case actions.SET_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case actions.GET_STATE:
      return state
    default:
      return state
  }
}
