/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable dot-notation */
/* eslint-disable prefer-const */
import React, { useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import { UploadOutlined } from '@ant-design/icons'
import { Row, Col, PageHeader, Descriptions, Card, Button, Tag } from 'antd'
import { useRouteMatch } from 'react-router-dom'
import { ApplicationContext } from '@antegma/accallio-cloud-react-components/lib/app/context'
import { ContentItem } from '@antegma/accallio-cloud-hootsuite-application-api-javacript-sdk'
import Axios from 'axios'
import Loader from '@antegma/accallio-cloud-react-components/lib/app/components/Loader'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/hootsuite/common/redux/interface'
import PrettyBytes from 'react-pretty-bytes'
import FormattedDuration, { TIMER_FORMAT } from 'react-intl-formatted-duration'
import videojs from 'video.js'
import contentSourceApi from '~/services/ContentSource'
import tokenApi from '~/services/Token'
import VideoPlayer from '~/components/videoplayer'
import TagList from '~/components/tag-list'
import styles from './styles.module.scss'

const ContentDetailView: React.FunctionComponent = () => {
  const route = useRouteMatch<{ contentId: string }>()
  const ctx = useContext(ApplicationContext)
  const [contentItem, setContentItem] = useState<ContentItem | undefined>(undefined)
  const oidc = useSelector((state: TDefaultSagas) => state.oidc)

  let hootsuiteUserId: string
  if (ctx.extraQueryParams !== undefined) {
    hootsuiteUserId = ctx.extraQueryParams.hootsuiteUserId
  }

  const options = { headers: { Authorization: `Bearer ${oidc.user?.access_token}` } }

  const {
    params: { contentId },
  } = route

  React.useEffect(() => {
    contentSourceApi
      .getContentItem(contentId, options)
      .then((response) => {
        setContentItem(response.data)
        console.log(contentItem)
      })
      .catch((reason) => {
        console.log(reason)
      })
  }, [contentId])

  const openComposer = (): void => {
    let contentItemId = ''

    if (contentItem !== undefined && contentItem.id !== undefined) {
      contentItemId = contentItem.id
      if (contentItem.mediaType === 'Video') {
        contentSourceApi.uploadContentItemToHootsuite(contentItemId, options).then((response) => {
          let hootsuiteMediaId = response.data
          let dateTime = Date.now()
          let timestamp = Math.floor(dateTime / 1000)
          let userId = hootsuiteUserId
          console.log(`hootsuite user id for attach to media: ${hootsuiteUserId}`)
          let input = `${userId}${timestamp}${hootsuiteMediaId}`

          tokenApi.generateToken(input, options).then((tokenResponse) => {
            let token = tokenResponse.data

            window['hsp']['attachMediaV2']({
              mediaId: hootsuiteMediaId,
              timestamp,
              token,
            })
          })
        })
      } else if (contentItem.mediaType === 'Image') {
        const directUpload = false
        let url = contentItem.urls?.original
        let dateTime = Date.now()
        let timestamp = Math.floor(dateTime / 1000)
        console.log(`hootsuite user id for attach file to message: ${hootsuiteUserId}`)
        let input = `${hootsuiteUserId}${timestamp}${url}`

        tokenApi.generateToken(input, options).then((tokenResponse) => {
          let token = tokenResponse.data
          let fileNameParts = contentItem.name?.split('.')
          let extension = ''
          if (fileNameParts !== undefined) {
            extension = fileNameParts[fileNameParts.length - 1]
          }

          window['hsp']['attachFileToMessageV2']({
            url,
            name: contentItem.name,
            extension,
            timestamp,
            token,
          })
        })
      }
    }
  }

  function Preview(): JSX.Element {
    if (contentItem?.mediaType === 'Image') {
      return (
        <div className={styles.image_preview_container}>
          <img className={styles.image_preview_image} src={contentItem?.urls?.preview} alt={`Preview of ${contentItem.name}`} />
        </div>
      )
    }

    let source = ''

    if (contentItem !== undefined && contentItem?.urls !== undefined && contentItem?.urls.original !== undefined) {
      source = contentItem?.urls?.original
    }

    let videoJsOptions: videojs.PlayerOptions = {
      autoplay: false,
      preload: 'metadata',
      poster: contentItem?.urls?.preview,
      controls: true,
      fluid: true,
      sources: [
        {
          src: source,
        },
      ],
    }

    return <VideoPlayer options={videoJsOptions} />
  }

  function Duration(): JSX.Element {
    if (contentItem !== undefined && contentItem.duration !== undefined) {
      return (
        <Descriptions.Item label="Duration">
          <FormattedDuration seconds={contentItem.duration / 1000} format={TIMER_FORMAT} />
        </Descriptions.Item>
      )
    }
    return <></>
  }

  return contentItem ? (
    <PageHeader title={`${contentItem.name}`}>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={18}>
          <Preview />
        </Col>
        <Col xs={24} lg={6}>
          <Button className={styles.open_composer_btn} type="primary" shape="round" size="large" icon={<UploadOutlined />} onClick={openComposer}>
            Open in Composer
          </Button>
          <Card title="Information" bordered={false}>
            <Descriptions size="small" column={1}>
              <Descriptions.Item label="Name">{contentItem.name}</Descriptions.Item>
              <Descriptions.Item label="Description">{contentItem.description}</Descriptions.Item>
              <Descriptions.Item label="Tags">
                <TagList tags={contentItem.tags} />
              </Descriptions.Item>
              <Descriptions.Item label="Created">{contentItem.created}</Descriptions.Item>
              <Descriptions.Item label="Created By">{contentItem.createdBy}</Descriptions.Item>
              <Descriptions.Item label="Last Modified">{contentItem.lastUpdated}</Descriptions.Item>
              <Descriptions.Item label="Last Modified By">{contentItem.lastUpdatedBy}</Descriptions.Item>
            </Descriptions>
          </Card>
          <Card title="Metadata" bordered={false}>
            <Descriptions size="small" column={1}>
              <Descriptions.Item label="Mime Type">{contentItem.mimeType}</Descriptions.Item>
              <Descriptions.Item label="Width">{contentItem.width}</Descriptions.Item>
              <Descriptions.Item label="Height">{contentItem.height}</Descriptions.Item>
              <Descriptions.Item label="Color">{contentItem.color}</Descriptions.Item>
              <Descriptions.Item label="Size">
                <PrettyBytes bytes={contentItem.size} />
              </Descriptions.Item>
              <Duration />
            </Descriptions>
          </Card>
          <Card title="Copyright & License" bordered={false}>
            <Descriptions size="small" column={1}>
              <Descriptions.Item label="Copyright">{contentItem.copyright}</Descriptions.Item>
              <Descriptions.Item label="License">{contentItem.license}</Descriptions.Item>
              <Descriptions.Item label="Terms & Conditions">{contentItem.termsAndConditions}</Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      </Row>
    </PageHeader>
  ) : (
    <Loader />
  )
}

export default {
  name: 'ContentDetailView',
  routeProps: {
    path: '/contentdetails/:contentId',
    component: ContentDetailView,
  },
}
