import React from 'react'
import { ContentItem } from '@antegma/accallio-cloud-hootsuite-application-api-javacript-sdk'
import { Masonry } from '~/components/masonry'
import MediaCardView from '~/components/media-cardview'

type TProps = {
  items: ContentItem[]
}
const CollectionMasonry: React.FunctionComponent<TProps> = ({ items }) => {
  const itemToElement = (item: ContentItem): JSX.Element => <MediaCardView key={item.id} contentItem={item} />

  return <Masonry>{items.map(itemToElement)}</Masonry>
}

export default React.memo(CollectionMasonry)
