import { TokenApi } from '@antegma/accallio-cloud-hootsuite-application-api-javacript-sdk'
import { Api } from '@antegma/accallio-cloud-react-components/lib/service/api'
import { getEnv } from '@antegma/accallio-cloud-react-components/lib/app/util/env'

type TEnv = {
  CONTENTSOURCE_API_BASE_PATH: string
}

const env = getEnv<TEnv>({
  CONTENTSOURCE_API_BASE_PATH: process.env.REACT_APP_CONTENTSOURCE_API_BASE_PATH || 'https://hootsuite-canto-api.apps.accallio.com',
})

const tokenApi = new TokenApi(undefined, env.CONTENTSOURCE_API_BASE_PATH, Api.instance.axiosInstance)

export default tokenApi
