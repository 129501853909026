/* eslint-disable import/prefer-default-export */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import React from 'react'
import style from './index.module.scss'

export const Masonry = ({ children }): JSX.Element => {
  return (
    <div className={style.masonry}>
      {children?.map((child, index) => (
        <div key={index} className={style.masonryBrick}>
          {child}
        </div>
      ))}
    </div>
  )
}
