import React from 'react'
import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import { ContentPathEntry } from '@antegma/accallio-cloud-hootsuite-application-api-javacript-sdk'

type TProps = {
  contentPathEntries: ContentPathEntry[] | undefined
}
const CantoBreadCrumb: React.FunctionComponent<TProps> = ({ contentPathEntries }) => {
  const tagToElement = (contentPathEntry: ContentPathEntry): JSX.Element => (
    <Breadcrumb.Item key={contentPathEntry.id}>
      <Link to={`/contents/${contentPathEntry.id}`}>{contentPathEntry.name}</Link>
    </Breadcrumb.Item>
  )

  if (contentPathEntries !== undefined) {
    return (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/contents/home">Libraries</Link>
        </Breadcrumb.Item>
        {contentPathEntries.map(tagToElement)}
      </Breadcrumb>
    )
  }
  return (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/contents/home">Libraries</Link>
      </Breadcrumb.Item>
    </Breadcrumb>
  )
}

export default React.memo(CantoBreadCrumb)
