/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { all, put, takeEvery, select } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/hootsuite/common/redux/interface'
import contentSourceApi from '~/services/ContentSource'
import actions, { setLoading } from './actions'

export interface IActionLoadCollectionItems {
  contentItemId: string
}

function* LOAD_COLLECTION({ payload }: AnyAction) {
  const { contentItemId } = payload
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  yield put(setLoading(true))

  yield put({
    type: actions.SET_STATE,
    payload: {
      currentCollectionId: contentItemId,
      currentCollectionItems: [],
    },
  })

  const options = { headers: { Authorization: `Bearer ${accessToken}` } }
  const collectionItems = yield contentSourceApi.getContentItems(undefined, contentItemId, undefined, options)

  yield put({
    type: actions.SET_STATE,
    payload: {
      currentContentId: contentItemId,
      items: collectionItems.items,
      page: collectionItems.page,
      pageSize: collectionItems.pageSize,
      total: collectionItems.total,
      loading: false,
    },
  })
}

function* LOAD_STATE() {
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  // console.log('LOAD_STATE (collections)', accessToken);

  yield put(setLoading(true))

  try {
    const options = { headers: { Authorization: `Bearer ${accessToken}` } }
    const collections = yield contentSourceApi.getContentItems(undefined, undefined, undefined, options)
    // console.log('LOAD_STATE (collections)', collections);
    if (collections) {
      yield put({
        type: actions.SET_STATE,
        payload: collections,
      })
    }
  } catch (error) {
    // console.log('LOAD_STATE (collections) got error', error);
    yield put({
      type: actions.SET_STATE,
      payload: [],
    })
  }

  yield put(setLoading(false))
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_STATE, LOAD_STATE), takeEvery(actions.LOAD_COLLECTION, LOAD_COLLECTION)])
}
