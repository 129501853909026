import React from 'react'
import { Tag } from 'antd'

type TProps = {
  tags: string[] | undefined
}
const TagList: React.FunctionComponent<TProps> = ({ tags }) => {
  const tagToElement = (tag: string): JSX.Element => <Tag color="blue">{tag}</Tag>

  if (tags !== undefined) {
    return <>{tags.map(tagToElement)}</>
  }
  return <></>
}

export default React.memo(TagList)
